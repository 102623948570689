@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-bg--color: #282c34;
  --secondary-bg--color: #f4f4f4;

  --font-family: 'Nunito Sans', sans-serif;
  --font-size: 14px;
  --font-size--x2: 18px;
  --font-size--x3: 22px;
  --font-size--x4: 26px;
  --font-size--h1: 2rem;
  --font-size--h2: 1.5rem;
  --font-size--h3: 1.3rem;
  --font-size--h4: 1rem;
  --primary-font--color: #fff;
  --secondary-font--color: #e3e3e3;
}

html,
body {
  font-size: 14px;
  font-size: var(--font-size);
  font-family: 'Nunito Sans', sans-serif;
  font-family: var(--font-family);
  color: #fff;
  color: var(--primary-font--color);
}

.App {
  background-color: #282c34;
  background-color: var(--main-bg--color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  color: var(--primary-font--color);

  text-align: center;
}

ul {
  display: block;
  list-style-type: none;
  padding: 0;
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 20px;
  text-align: left;
}

li.sm-icon {
  display: inline-block;
  margin-left: 5vh;
}

li.sm-icon a {
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  font-size: var(--font-size--x2);

}

li.sm-icon a,
li.sm-icon a:visited,
li.sm-icon a:link,
li.sm-icon a:active {
  color: #fff;
  color: var(--primary-font--color);
}

li.sm-icon a:hover {
  color: #e3e3e3;
  color: var(--secondary-font--color);
}

header {
  width: 100%;
  margin-top: -30px;
  position: fixed;
  top: 0;
  text-align: right;
  z-index: 2;
}

li.nav-li {
  display: inline-block;
  margin: 5vh 5vh 0 0;
}

a.nav-link {
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s ease-in;
}

a.nav-link:link, a.nav-link:visited {
  color: #fff;
  color: var(--primary-font--color)
}

a.nav-link:hover {
  color: #e3e3e3;
  color: var(--secondary-font--color);
}

.container {
  /* height: 100vh; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.container .welcome h1 {
  font-size: 3rem;
  margin: 4px 0px;
}

.container p {
  font-size: 2rem;
  padding: 0px;
  margin: 0px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-align: center;
  overflow: hidden;
}

.container p span.typed-text {
  font-weight: normal;
  color: #e3e3e3;
  color: var(--secondary-font--color);
}

.container p span.cursor {
  display: inline-block;
  background-color: #ccc;
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}

.container p span.cursor.typing {
  animation: none;
}

@keyframes blink {
  0% {
    background-color: #ccc;
  }
  49% {
    background-color: #ccc;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: #ccc;
  }
}

